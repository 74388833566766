import React from "react";
import ArrowRightSvg from "../assets/images/right-arrow.inline.svg";
import styled from "styled-components";

const CtaLinkWrapper = styled.div`
  span {
    display: inline-block;
    margin-right: 0.75rem;
  }

  svg {
    width: 0.75em;
    margin-top: 0.25rem;
    fill: var(--bright-green);
  }

  &:hover {
    text-decoration: underline;
  }
`;

const Cta = ({ children }) => (
  <CtaLinkWrapper>
    <span>{children}</span> <ArrowRightSvg width="17" height="24" />
  </CtaLinkWrapper>
);

export default Cta;
