import React from "react";
import { graphql, Link } from "gatsby";
import MetaTags from "../components/MetaTags";
import Cta from "../components/Cta";
import styled from "styled-components";

const PortfolioGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  background: white;
  max-width: 1440px;
  margin: 0 1rem;

  @media (min-width: 700px) {
    grid-template-columns: 50% 50%;
  }

  @media (min-width: 1200px) {
    margin: 0 auto;
    grid-template-columns: 25% 25% 25% 25%;
  }

  * {
    margin-top: 0;
  }

  > div {
    + div {
      border-top: 1px dashed #c6c6c6;

      @media (min-width: 700px) {
        border-top: none;
      }
    }

    > * {
      display: flex;
      height: 100%;
      padding: 2rem 1rem 3rem;
      flex-direction: column;
      align-items: center;
      text-align: center;
      text-decoration: none;

      @media (min-width: 700px) {
        padding: 2rem;
      }

      > div {
        display: flex;
        flex: 1;
        align-items: center;
      }

      h2 {
        margin: 0 0 3rem;
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 1;
        color: #070707;

        @media (min-width: 700px) {
          min-height: 3em;
        }
      }

      p {
        margin-bottom: 2.5rem;
        font-size: 1.3rem;
        text-align: left;
        color: #070707;
      }

      .icon {
        margin: 2rem 2rem 2rem;
        transition: 0.25s transform ease;

        @media (min-width: 700px) {
          margin: 5rem 2rem 5rem;
        }
      }
    }

    a:hover {
      .icon {
        transform: scale(1.06);
      }
    }
  }
`;

function PortfolioItemContent(content) {
  return (
    <>
      <img
        alt=""
        className="icon"
        src={content.frontmatter.image.publicURL}
        width="100"
        height="100"
      />
      <h2>{content.frontmatter.title}</h2>
      <p>{content.frontmatter.summary}</p>
    </>
  );
}

const ComingSoonWrapper = styled.span`
  font-weight: bold;
`;

export default function PortfolioPage({ data: { allMdx: portfolio } }) {
  return (
    <>
      <MetaTags title="Portfolio" />
      <PortfolioGridWrapper>
        {portfolio.edges.map(({ node: portfolio }, i) => (
          <div key={`${portfolio.frontmatter.slug}-${i}`}>
            {portfolio.frontmatter.published ? (
              <Link to={`${portfolio.frontmatter.slug}`}>
                {PortfolioItemContent(portfolio)}
                <Cta>Read more</Cta>
              </Link>
            ) : (
              <div to={`${portfolio.frontmatter.slug}`}>
                {PortfolioItemContent(portfolio)}
                <div>
                  <ComingSoonWrapper>Article coming soon</ComingSoonWrapper>
                </div>
              </div>
            )}
          </div>
        ))}
      </PortfolioGridWrapper>
    </>
  );
}

export const pageQuery = graphql`
  query Portfolio {
    allMdx(
      filter: { fields: { collection: { eq: "portfolio" } } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      totalCount
      edges {
        node {
          frontmatter {
            title
            summary
            slug
            published
            image {
              ...ImageFields
            }
          }
          body
        }
      }
    }
  }
`;
